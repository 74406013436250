<template>
  <b-modal id="cookie-modal" class="cookie-modal" centered>
    <template #modal-header>
      <h5>Personliga inställningar</h5>
      <inline-svg
        class="close-modal"
        @click="handleCloseClick"
        height="20"
        :src="$svg.getSvgUrl('ic-close.svg')"
        width="20"
      />
    </template>

    <p class="text small">
      Vi använder kakor (cookies) för att säkerställa de grundläggande
      funktionerna på webbplatsen och för att förbättra din upplevelse. Du kan
      välja separat för varje kategori och du kan ändra ditt val när du vill.
      För mer information om cookies och hur vi använder dem, läs
      <span class="cookie-link" @click="handleLinkClick"
        >vår cookiepolicy.</span
      >
    </p>

    <CookieSettingItem
      v-for="setting in settings"
      :key="setting.id"
      :setting="setting"
    />

    <template #modal-footer>
      <div class="footer-buttons">
        <b-button
          class="yes-button hover-light"
          @click="handleYesClick"
          ref="yesButton"
          variant="purple"
          >Ja till alla</b-button
        >
        <b-button
          class="save-button hover-light"
          @click="handleSaveClick"
          variant="outline-almost-black"
          >Spara inställningar</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
const CookieSettingItem = () => import("@/components/common/CookieSettingItem");

export default {
  name: "CookieModal",
  components: {
    CookieSettingItem
  },

  data() {
    return {
      settings: [
        { id: 1, text: "Strictly necessary cookies", value: false },
        { id: 2, text: "Lorem ipsum dolor", value: false },
        { id: 3, text: "Serpentum ne delior", value: false }
      ]
    };
  },

  methods: {
    handleLinkClick() {
      this.$bvModal.hide("cookie-modal");
      if (this.$route.path === "/cookie-policy") return;
      this.$router.push("/cookie-policy");
    },

    handleCloseClick() {
      this.$bvModal.hide("cookie-modal");
    },

    handleYesClick() {
      this.settings.forEach(setting => {
        setting.value = true;
      });
    },

    handleSaveClick() {
      this.settings.forEach(setting =>
        console.log(`${setting.text}: `, setting.value)
      );
      this.$bvModal.hide("cookie-modal");
    }
  },

  watch: {
    settings: {
      deep: true,
      handler() {
        if (this.settings.every(setting => setting.value)) {
          this.$refs.yesButton.disabled = true;
        } else {
          this.$refs.yesButton.disabled = false;
        }
      }
    }
  }
};
</script>
